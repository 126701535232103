<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header></nav-header>
    
    <div class="app-container examinationOver">
      <div class="box">
        <h1 class="box-h1">考試結束</h1>
        <h2 class="box-h2">感謝您參加JEPT測驗</h2>
        <div class="box-btn" @click="closePage">回到 JEPT 網站</div>
      </div>
    </div>
    
    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: 'ExaminationOver',
  created() {
    setTimeout(() => {
      // 銷毀實例
      this.$store.dispatch('agora/stopScreenShare')
      // 跳轉網頁
      window.location.replace('http://jept.joy.com.tw/')
    }, 5000)
  },
  methods: {
    // 回到 JEPT 網站
    closePage() {
      // 銷毀實例
      this.$store.dispatch('agora/stopScreenShare')
      // 跳轉網頁
      window.location.replace('http://jept.joy.com.tw/')
      // window.open("about:blank", "_top").close()
    },
  },
}
</script>

<style lang="less" scoped>
.examinationOver {
  .box {
    margin: 100px auto;
    width: 1170px;
    height: 500px;
    background-image: url('../../assets/image/examination/examinationOver.png');
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: center;
    .box-h1 {
      padding: 120px 0 32px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 80px;
      color: #FFFFFF;
    }
    .box-h2 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 40px;
      color: #FFFFFF;
    }
    .box-btn {
      margin: 45px auto 0;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 70px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #0063DD;
    }
  }
}
</style>